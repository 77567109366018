"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("../helpers/http");
var q_1 = require("../helpers/q");
var params_1 = require("./params");
var store_1 = require("./store");
var Config = /** @class */ (function () {
    function Config(storage, params, configJson) {
        this.configJson = configJson;
        this.params = params;
        if (!this.params.books.find(function (book) { return book.book === storage.book; })) {
            storage.book = this.params.books[0].book;
        }
        if (storage.name && storage.name.length > 11) {
            storage.name = '';
        }
        this.defaults = Object.assign({
            book: this.params.books[0].book,
            name: '',
            gender: 'fig1_w',
            haircolor: 'blo'
        }, storage);
        if (!['fig1_w', 'fig1_m'].includes(this.defaults.gender)) {
            this.defaults.gender = 'fig1_w';
        }
        for (var _i = 0, _a = this.configJson.options; _i < _a.length; _i++) {
            var option = _a[_i];
            option.enabled = true;
        }
    }
    return Config;
}());
exports.default = {
    load: function () {
        var params = new params_1.default().getParams();
        var storage = new store_1.default().get();
        var configJSON = http_1.default.get(params.base + "config/" + params.langUI + "-" + params.langBook + ".json");
        return q_1.default.all([configJSON])
            .then(function (responses) { return new Config(storage, params, responses[0].data); }).catch(function () { throw new Error('Failed to load configuration'); });
    }
};
