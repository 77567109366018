"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("core-js");
require("./extensions/globals");
require("./extensions/angular");
var prelude_1 = require("./prelude/prelude");
var config_1 = require("./prelude/config");
var store_1 = require("./prelude/store");
var initialized = false;
function initialize() {
    if (initialized) {
        return;
    }
    initialized = true;
    config_1.default.load().then(function (config) {
        prelude_1.default.constant('config', config);
        var $root = document.querySelector('ib-app');
        angular.bootstrap($root, ['ib'], { strictDi: config.params.strictDi });
    });
}
window.ibApplication = {
    init: initialize
};
if ($('ib-app').length) {
    initialize();
}
var ApplicationState = /** @class */ (function () {
    function ApplicationState() {
        this.direction = 'prev';
        this.onResize();
    }
    ApplicationState.prototype.onResize = function () {
        this.isMobile = window.matchMedia('(max-width: 599px)').matches;
        this.isDesktop = !this.isMobile;
    };
    return ApplicationState;
}());
exports.ApplicationState = ApplicationState;
function ApplicationDirective(config, prelude, appState, testFeatures, track, resizer, $timeout) {
    return {
        templateUrl: config.params.base + 'templates/application.html',
        link: function (scope, element) {
            scope.element = element;
            scope.callback = function (result) {
                console.log(result);
            };
            resizer.init(element, scope.callback);
        },
        controller: /** @class */ (function () {
            function AppController($scope, prelude, testFeatures) {
                var _this = this;
                this.testFeatures = testFeatures;
                /* develblock:start */
                this.debug = config.params.debug ? true : false;
                /* develblock:end */
                this.prelude = prelude;
                this.variant = config.params.variantId;
                this.store = new store_1.default();
                this.lang = {
                    book: config.params.langBook,
                    ui: config.params.langUI
                };
                this.ui = Object.assign({}, config.configJson.ui, config.params.ui);
                $scope.$watch(function () { return _this.prelude; }, function () {
                    _this.store.set(_this.prelude);
                }, true);
                window.addEventListener('resize', function () {
                    appState.onResize();
                    $scope.$apply();
                }, false);
            }
            AppController.prototype.stepBook = function (step) {
                var _this = this;
                var books = config.params.books;
                var count = books.length;
                var index = books.findIndex(function (book) { return book.book === _this.prelude.book; });
                index += count + step;
                index %= count;
                appState.direction = step > 0 ? 'next' : 'prev';
                $timeout(function () { _this.prelude.book = books[index].book; });
            };
            AppController.prototype.getDirection = function () {
                return appState.direction;
            };
            AppController.prototype.onDetailsClick = function () {
                track.track({
                    eventAction: 'Click_gestalten',
                    eventLabel: this.prelude.book
                });
            };
            AppController.prototype.onBookClick = function () {
                track.track({
                    eventAction: 'Click_book_title',
                    eventLabel: this.prelude.book
                });
                window.location.href = this.getBookLink();
            };
            AppController.prototype.getBookLink = function () {
                var _this = this;
                var book = config.params.books.find(function (book) { return book.book === _this.prelude.book; });
                return book.link;
            };
            AppController.prototype.hasFeature = function (key) {
                return this.testFeatures.hasFeature(key);
            };
            return AppController;
        }()),
        controllerAs: 'app'
    };
}
prelude_1.default.directive('ibApp', ApplicationDirective)
    .service('appState', ApplicationState)
    .config(function ($sceDelegateProvider) {
    $sceDelegateProvider.resourceUrlWhitelist([
        'self',
        'https://*.framily.de/**',
        'https://*.framily.local/**'
    ]);
});
