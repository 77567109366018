"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function InputGenderDirective(config, prelude) {
    return {
        scope: true,
        bindToController: {
            option: '='
        },
        controller: /** @class */ (function () {
            function InputGenderController(prelude) {
                this.active = false;
                this.prelude = prelude;
            }
            InputGenderController.prototype.set = function () {
                if (!this.option.enabled) {
                    return;
                }
                this.active = true;
                this.prelude.gender = this.option.gender;
            };
            InputGenderController.prototype.hide = function () {
                this.active = false;
            };
            InputGenderController.prototype.isActive = function () {
                return this.active;
            };
            return InputGenderController;
        }()),
        controllerAs: 'genderCtrl'
    };
}
exports.default = angular.module('ib.inputgender', [])
    .directive('ibInputGender', InputGenderDirective);
