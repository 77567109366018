"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TestFeatures = /** @class */ (function () {
    function TestFeatures(config) {
        this.config = config;
        this.testFeatureClasses = [];
        var testFeaturesParam = this.config.params.testFeatures || [];
        if (testFeaturesParam && typeof testFeaturesParam === 'string') {
            this.testFeatures = this.config.configJson.testFeatures[testFeaturesParam] || [];
        }
        else if (testFeaturesParam && testFeaturesParam instanceof Array) {
            this.testFeatures = testFeaturesParam;
        }
        for (var _i = 0, _a = this.testFeatures; _i < _a.length; _i++) {
            var feature = _a[_i];
            this.testFeatureClasses.push('ib-feature-'.concat(feature.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()));
        }
    }
    TestFeatures.prototype.hasFeature = function (key) {
        return this.testFeatures.includes(key);
    };
    TestFeatures.prototype.getCssClasses = function () {
        return this.testFeatureClasses.join(' ');
    };
    return TestFeatures;
}());
exports.TestFeatures = TestFeatures;
exports.default = angular.module('ib.testFeatures', [])
    .service('testFeatures', TestFeatures);
