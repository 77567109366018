"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Store = /** @class */ (function () {
    function Store() {
    }
    Store.prototype.isEnabled = function () {
        var enabled = true;
        var localStorage;
        try {
            localStorage = window.localStorage;
        }
        catch (e) {
            enabled = false;
        }
        return enabled;
    };
    Store.prototype.get = function () {
        var options = {};
        if (this.isEnabled()) {
            options = Object.assign({}, JSON.parse(window.localStorage.getItem('ibStorage')));
        }
        return options;
    };
    Store.prototype.set = function (options) {
        if (this.isEnabled()) {
            var storage = this.get();
            options = Object.assign(storage, options);
            window.localStorage.setItem('ibStorage', JSON.stringify(options));
        }
    };
    return Store;
}());
exports.default = Store;
