"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var patternRestrict_1 = require("../helpers/patternRestrict");
var filter_1 = require("../helpers/filter");
var locale_1 = require("../helpers/locale");
var testFeatures_1 = require("../helpers/testFeatures");
var NgAnimate = require("angular-animate");
var form_1 = require("../form/form");
var preview_1 = require("../preview/preview");
var track_1 = require("./track");
var resizer_1 = require("../helpers/resizer");
require("angular-hammer");
var Prelude = /** @class */ (function () {
    function Prelude(config) {
        Object.assign(this, config.defaults);
    }
    return Prelude;
}());
exports.Prelude = Prelude;
exports.default = angular.module('ib', [
    filter_1.default, NgAnimate, patternRestrict_1.default, form_1.default, preview_1.default, track_1.default, testFeatures_1.default, locale_1.default, resizer_1.default, 'hmTouchEvents'
])
    .service('prelude', Prelude);
