"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function PreviewDirective(config, prelude) {
    return {
        templateUrl: config.params.base + "templates/preview/preview.html",
        scope: true,
        controller: /** @class */ (function () {
            function PreviewController() {
                this.prelude = prelude;
                this.books = config.params.books;
            }
            return PreviewController;
        }()),
        controllerAs: 'previewCtrl'
    };
}
function BookDirective(config, $timeout, resizer) {
    return {
        scope: true,
        bindToController: {
            bookId: '='
        },
        link: function (scope, element) {
            $timeout(function () { resizer.resize(); });
        },
        controller: /** @class */ (function () {
            function BookController() {
                this.bookConfig = config.configJson.books[this.bookId];
                this.layer = this.bookConfig.layer;
                this.title = this.bookConfig.title;
                this.ratio = this.bookConfig.size[0] / this.bookConfig.size[1];
            }
            return BookController;
        }()),
        controllerAs: 'bookCtrl'
    };
}
function BookLayerDirective($compile, prelude, config) {
    return {
        scope: {
            layer: '='
        },
        link: function (scope, element) {
            scope.prelude = prelude;
            scope.$watch(function () { return scope.prelude; }, function (prelude) {
                Object.assign(scope, prelude);
            }, true);
            element.append($compile("<img ng-src=\"" + config.params.base + "books/{{book}}/cover/" + scope.layer + "\" />")(scope));
        }
    };
}
function BookTitleDirective($compile, $interpolate, prelude, config) {
    return {
        scope: {
            title: '='
        },
        link: function (scope, element) {
            scope.math = Math;
            scope.prelude = prelude;
            scope.$watch(function () { return scope.prelude; }, function (prelude) {
                Object.assign(scope, prelude);
            }, true);
            element.append($compile(scope.title)(scope));
        }
    };
}
exports.default = angular.module('ib.preview', [])
    .directive('ibPreview', PreviewDirective)
    .directive('ibBook', BookDirective)
    .directive('ibBookLayer', BookLayerDirective)
    .directive('ibBookTitle', BookTitleDirective);
