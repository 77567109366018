"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function assertProperties(obj, properties) {
    for (var _i = 0, properties_1 = properties; _i < properties_1.length; _i++) {
        var property = properties_1[_i];
        if (obj[property] === undefined) {
            throw new Error('Missing param: ' + property);
        }
    }
}
var Params = /** @class */ (function () {
    function Params() {
        // set some default params:
        var defaults = {
            langBook: 'de',
            langUI: 'de',
            variantId: 'default'
        };
        // read params from data-attribute:
        this.params = Object.assign(defaults, JSON.parse(document.querySelector('ib-app').getAttribute('data-ib-params')));
        assertProperties(this.params, ['books', 'base']);
    }
    Params.prototype.getParams = function () {
        return this.params;
    };
    return Params;
}());
exports.default = Params;
