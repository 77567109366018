"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function Locale($compile, $parse, prelude, config, testFeatures) {
    return {
        restrict: 'AE',
        link: function (scope, elem, attrs) {
            Object.assign(scope, prelude);
            scope.ui = Object.assign({}, config.configJson.ui);
            scope.hasFeature = testFeatures.hasFeature.bind(testFeatures);
            elem.append($compile('<span>' + $parse(attrs.value)(scope) + '</span>')(scope));
        }
    };
}
exports.Locale = Locale;
exports.default = angular.module('ib.locale', [])
    .directive('ibLocale', Locale);
