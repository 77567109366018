"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function dasherizeFilter() {
    return function (input) {
        return input.toLowerCase().replace(/[^a-z0-9]/g, '-');
    };
}
function toIntFilter() {
    return function (input) {
        var int = parseInt(input, 10);
        return isNaN(int) ? input : int;
    };
}
exports.default = angular.module('ib.filter', [])
    .filter('dasherize', dasherizeFilter)
    .filter('toInt', toIntFilter);
