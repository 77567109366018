"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Resizer = /** @class */ (function () {
    function Resizer() {
        this.callbackArgs = {};
    }
    Resizer.prototype.init = function (context, callback) {
        this.callback = callback;
        this.fitTo(context);
    };
    Resizer.prototype.fitTo = function (context) {
        this.$el = $(context);
        this.$preview = this.$el.find('#ib-preview');
        this.resize();
        $(window).on('resize', this.resize.bind(this));
    };
    Resizer.prototype.resize = function () {
        if (!this.$el) {
            return;
        }
        var that = this;
        this.$preview.find('.ib-book-scale').each(function () {
            var $book = $(this), previewWidth = that.$preview.width(), previewHeight = that.$preview.height(), bookWidth = $book.width(), bookHeight = Math.round(bookWidth / Number($book.attr('ratio'))), factor = Math.min(previewWidth / bookWidth, previewHeight / bookHeight), top = (previewHeight - bookHeight) / 2, left = (previewWidth - bookWidth) / 2;
            $book.css({
                'transform': "scale(" + factor + ")",
                'top': top + "px",
                'left': left + "px",
                'height': bookHeight + "px"
            });
        });
    };
    return Resizer;
}());
exports.Resizer = Resizer;
exports.default = angular.module('pb.resizer', [])
    .service('resizer', Resizer);
