"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Track = /** @class */ (function () {
    function Track(config) {
        this.config = config;
        this.date = Date.now();
        this.enabled = false;
        this.api = window[config.params.apiName];
        if (!this.api) {
            console.error("framily api not avilable: \"window." + this.config.params.apiName + "\"");
        }
        else if (!this.api.hasOwnProperty('sendEvent')) {
            console.error("method for event tracking not avilable: \"window." + this.config.params.apiName + ".sendEvent\"");
        }
        else {
            // NOTE: Tracking temporary disabled!
            // this.enabled = true;
        }
    }
    Track.prototype.track = function (fieldsObject) {
        if (!this.enabled) {
            return;
        }
        fieldsObject = Object.assign({
            eventCategory: 'IB',
            eventAction: 'unknown',
            value: this.getTime()
        }, fieldsObject);
        this.api.sendEvent(fieldsObject);
    };
    Track.prototype.getTime = function () {
        return Math.floor((Date.now() - this.date) / 1000);
    };
    return Track;
}());
exports.Track = Track;
exports.default = angular.module('pb.track', [])
    .service('track', Track);
