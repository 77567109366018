"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function InputHaircolorDirective(config, prelude) {
    return {
        scope: true,
        bindToController: {
            disable: '='
        },
        controller: /** @class */ (function () {
            function InputhaircolorController(prelude) {
                this.prelude = prelude;
            }
            InputhaircolorController.prototype.set = function (haircolor) {
                if (this.disable) {
                    return;
                }
                this.prelude.haircolor = haircolor;
            };
            return InputhaircolorController;
        }()),
        controllerAs: 'haircolorCtrl'
    };
}
function InputHaircolorOptionDirective(config, prelude) {
    return {
        scope: true,
        bindToController: {
            haircolor: '='
        },
        controller: /** @class */ (function () {
            function InputhaircolorOptionController(prelude) {
                //
            }
            return InputhaircolorOptionController;
        }()),
        controllerAs: 'haircolorOptionCtrl'
    };
}
exports.default = angular.module('ib.inputhaircolor', [])
    .directive('ibInputHaircolor', InputHaircolorDirective)
    .directive('ibInputHaircolorOption', InputHaircolorOptionDirective);
