"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var input_gender_1 = require("../form/input-gender");
var input_haircolor_1 = require("../form/input-haircolor");
function FormDirective(config, prelude, track) {
    return {
        templateUrl: config.params.base + "templates/form/form.html",
        scope: true,
        controller: /** @class */ (function () {
            function FormController($scope) {
                var _this = this;
                this.prelude = prelude;
                this.options = config.configJson.options;
                this.haircolors = config.configJson.haircolors;
                this.disableHaircolor = false;
                this.label = config.configJson.ui.name;
                this.pattern = config.configJson.pattern;
                $scope.$watch(function () { return _this.prelude.book; }, function () {
                    var bookConfig = config.configJson.books[_this.prelude.book];
                    var disabledOptions = bookConfig.disable || [];
                    _this.disableHaircolor = disabledOptions.length > 1;
                    for (var _i = 0, _a = _this.options; _i < _a.length; _i++) {
                        var option = _a[_i];
                        var enable = true;
                        for (var _b = 0, disabledOptions_1 = disabledOptions; _b < disabledOptions_1.length; _b++) {
                            var disabled = disabledOptions_1[_b];
                            if (disabled === option.gender) {
                                enable = false;
                            }
                        }
                        option.enabled = enable;
                    }
                    for (var _c = 0, _d = _this.options; _c < _d.length; _c++) {
                        var option = _d[_c];
                        if (option.enabled && disabledOptions.length) {
                            _this.prelude.gender = option.gender;
                        }
                    }
                });
            }
            FormController.prototype.trackInput = function (action, label) {
                var fieldsObject = {
                    eventAction: action
                };
                if (label) {
                    Object.defineProperty(fieldsObject, 'eventLabel', {
                        value: label === 'fig1_w' ? 'Maedchen' : 'Junge',
                        enumerable: true
                    });
                }
                track.track(fieldsObject);
            };
            return FormController;
        }()),
        controllerAs: 'formCtrl'
    };
}
exports.default = angular.module('ib.form', [
    input_gender_1.default, input_haircolor_1.default
])
    .directive('ibForm', FormDirective);
