var _module = angular.module;
angular.module = function (name, requires, configFn) {
    requires = requires.map(function (req) {
        if (typeof req === 'string') {
            return req;
        }
        if (typeof req.name === 'undefined') {
            throw new Error('Missing name property');
        }
        return req.name;
    });
    return _module(name, requires);
};
